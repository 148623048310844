import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies';
import Axios from 'axios';
// import BaseButton from './components/ui/BaseButton.vue';
import VueNumber from 'vue-number-animation';


Vue.use(VueNumber)
Vue.use(VueCookies);

VueCookies.config('7d');
Vue.config.productionTip = false;


const SESS_ID = window.$cookies.get('SESSID');

//*ASK There is my spike api :)
Vue.prototype.$api ='https://back.rizaherb.kz/index.php?route='; 

Vue.prototype.$axios = Axios;
Vue.prototype.$axios.defaults.headers = {
  "x-oc-merchant-id": process.env.VUE_APP_MERCHANT_ID,
  "x-oc-merchant-language": "ru-ru",
  "x-oc-session": SESS_ID
}

Vue.prototype.$axios.defaults.withCredentials = true;

Vue.component('modal-message', () => import('./components/ui/ModalMessage.vue'));
// Vue.component('base-button', BaseButton)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
