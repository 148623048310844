export default {
  getCart: state => state.cart,
  itemsInCart: state => {
    if(state.cart.length) {
      return state.cart.reduce((sum, el) => sum += +el.quantity, 0)
    }
      return 0
  },
  getCurrency: (state) => state.cart[0].currency,
  getTotal: state => {
    if(state.cart.length) {
        return state.cart.reduce((sum, el) => (sum += el.price_raw * el.quantity), 0)
      // return state.cart.reduce((sum, el) => sum += el.total_raw, 0)
    } else {
      return 0
    }
  },
  discount: (state) => state.discount,
  getDiscountTotal: (state) => {
    if(state.discount && state.totalsAmount.length) {
      return state.totalsAmount && state.totalsAmount[2].value
    } else {
      return 0
    }
  },
  getError: (state) => state.error
}