export default {
  setUser(state, {SESS_ID, data}) {
    state.AUTH_ID = SESS_ID;
    state.user = {
      ...data
    }
  },
  saveErr(state, payload) {
    state.error = payload;
  },
  changeFieldsData(state, payload) {
    state.user = {
      ...state.user,
      ...payload
    }
  },
  changeFieldsAddress(state, payload) {
    state.user.address = {
      ...state.user.address,
      ...payload
    }
  }
}