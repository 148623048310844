import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state() {
    return {
      user: {},
      AUTH_ID: '',
      error: []
    }
  },
  actions,
  mutations,
  getters
}