<template>
  <section>
    <div class="background-style" v-if="visible" @click="$emit('close')"></div>
    <transition name="modal">
      <div
        v-if="visible"
        class="addtocard d-flex justify-content-center align-items-center m-auto"
      >
        <div class="my-5 wraper">
          <div class="greenline d-flex flex-row">
            <h1 class="mx-auto my-2">Добавить в корзину</h1>
            <!-- <img
              class="close my-auto"
              src="@/assets/img/Close.svg"
              @click="$emit('close')"
            /> -->
          </div>

          <div class="cart__block">
            <div class="cart__image">
              <img ref="productImage" :src="product.image" />
            </div>
            <div class="cart__text">
              <h4>{{ product.name }}</h4>
              <div class="calc">
                <h5>Количество:</h5>
                <span class="bordered" @click="decrement"> - </span>
                <div>{{ product.quantity }}</div>
                <span class="bordered" @click="increment"> + </span>
              </div>
              <h5 ref="price">
                {{ priceInteger * (product.quantity || 1) + product.currency }}
              </h5>

              <div class="card__options" v-if="product.options.length">
                <div
                  class="card__option"
                  v-for="option in product.options"
                  :key="option.name"
                >
                  <span>{{ option.name }}:</span>
                  <div class="card__variants">
                    <button
                      v-for="(variant, i) in option.option_value"
                      :key="variant.option_value_id"
                      @click="setOption($event, option.product_option_id, variant.option_value_id, variant)"
                      :data-num="i"
                      :class="{active: activeClass == i}"
                    >
                      {{ variant.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="total" v-if="getTotal">
            <h6>Итого в корзине товаров на сумму:</h6>
            <h3>{{ total }}</h3>
          </div>

          <div class="addtocart__purchase">
            <button type="button" class="btn" @click="passItemsToCart">
              Оформить заказ
            </button>
            <button type="button" class="btn" @click="closeModal">
              Продолжить покупки
            </button>
            <span>*Стоимость доставки зависит от тарифа курьерской службы</span>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  emits: ["close"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      reqired: true,
    },
  },
  data() {
    return {
      price: null,
      activeClass: null
    };
  },
  computed: {
    ...mapGetters("cart", ["getTotal"]),

    total() {
      return (
        this.getTotal
          .toFixed(2)
          .toString()
          .replace(/(.)(?=(\d{3})+$)/g, "$1 ") + this.product.currency
      );
    },
    priceInteger() {
      return this.price;
      // return this.product.special ? this.product.special : this.product.price
    },
  },
  methods: {
    ...mapActions("cart", ["addToCart"]),

    closeModal() {
      if (this.product.quantity > 0) {
        this.addToCart(this.product);
      }
      this.$emit("close");
    },

    async passItemsToCart() {
      await this.addToCart(this.product);
      this.$emit("close");
      this.$router.replace("/cart");
    },
    increment() {
      this.product.quantity += 1;
    },
    decrement() {
      if (this.product.quantity > 1) {
        this.product.quantity -= 1;
      }
    },
    setOption(e, option_id, variant_id, option) {
      this.activeClass = e.target.dataset.num;
      this.product.option = {
        [option_id]: variant_id
      }
      this.product.image = option.image;
      this.$refs.productImage.src = option.image;
      this.price = (this.product.special ? this.product.special : this.product.price) + option.price;
    },
  },
  watch: {
    product() {
      this.price = this.product.special
        ? this.product.special
        : this.product.price;
    },
  },
};
</script>

<style scoped>
.background-style {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 5;
}

.addtocard {
  width: 630px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  /* -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in; */
}

.addtocard .wraper {
  background-color: #ffffff;
  border-radius: 5px;
}

.addtocard .greenline {
  background: #75b051;
  width: 100%;
}

.addtocard h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;

  color: #ffffff;
}

.card__options {
  margin-top: 15px;
}

.card__options .card__variants {
  display: flex;
  gap: 0 2px;
  flex-wrap: nowrap;
  margin-top: 2px;
}
.card__options .card__option button {
  padding: 2px 5px;
  background: transparent;
  border: 1px solid #000;
  border-radius: 5px;
  color: #000;
}
.card__options .card__option button:hover,
.card__options .card__option button.active {
  border: 1px solid #75b051;
  color: #75b051;
}

.cart__block {
  display: flex;
  flex-wrap: nowrap;
  margin: 20px 0;
}
.cart__image {
  max-width: 200px;
}

.cart__image img {
  width: 100%;
  height: auto;
}

.calc {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  margin: 10px 0;
  color: #7a7a7a;
}

.bordered {
  font-family: "Exo 2", sans-serif;
  font-weight: normal;
  font-size: 21px;
  line-height: 22px;
  padding: 0 5px 2px 5px;
  margin: auto 10px;
  cursor: pointer;
}

.cart__text {
  padding: 30px 0;
}

.addtocard .wraper h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}
.addtocard .wraper h5 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 0;
  color: #7a7a7a;
}
.addtocard .wraper p {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 27px;

  color: #7a7a7a;
}
.addtocard .wraper h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  /* or 37px */

  color: #000000;
}
.addtocard .wraper h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  /* or 45px */

  color: #7a7a7a;
  margin-left: auto;
  margin-right: 15px;
}
.addtocard .wraper hr {
  height: 2px;
  background: #75b051;
}
.addtocard .close {
  margin-right: 15px;
  cursor: pointer;
}
.addtocard .wraper {
  width: 100%;
}

.total {
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.addtocart__purchase {
  display: flex;
  justify-content: space-around;
  margin: 20px 0 35px;
}

.addtocart__purchase > span {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  opacity: 0.75;
}

.btn {
  background-color: #7ab456;
  color: #ffffff;
  font-size: 18px;
  width: 100%;
  max-width: 230px;
  height: 50px;
}

.modal-enter-active {
  animation: modal 0.5s ease-out;
}

.modal-leave-active {
  animation: modal 0.5s ease-in reverse;
}

@keyframes modal {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.2);
  }
}

@media (max-width: 768px) {
  .addtocard {
    width: 100%;
    overflow: auto;
  }
}

@media (max-width: 480px) {
  .total {
    padding: 0 30px;
  }
  .addtocard .wraper h6 {
    font-size: 10px;
  }
  .addtocard .wraper h3 {
    font-size: 12px;
  }
  .btn {
    max-width: 150px;
    height: auto;
    font-size: 12px;
    flex: 1 1 40%;
  }
}
</style>
