import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth/index";
import products from "./modules/products/index";
import cart from "./modules/cart/index";
import wishlist from "./modules/wishlist/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    products,
    cart,
    wishlist,
  },
  state() {
    return {
      currency: "KAZ",
      geolocation: "KZ",
      order: {}
    };
  },
  actions: {
    setCurrency({ commit }, payload) {
      switch (payload) {
        case "RU":
          return commit("changeCurrency", "RUB")
        case "KZ":
          return commit("changeCurrency", "KAZ")
        case "KG":
          return commit("changeCurrency", "KGS")
        case "UA":
          return commit("changeCurrency", "KAZ")
        default:
          return commit("changeCurrency", "USD")
      }
        // return commit("changeCurrency", payload);      
    },

    async setGeolocate({dispatch, commit }) {
      const geo = await fetch("https://ipapi.co/json");
      const geoData = await geo.json();

      commit("changeGeolocate", geoData.country_code);
      dispatch("setCurrency", geoData.country_code)
    },

    orderKeeper({commit}, payload) {
      console.log(payload)
      commit("setOrder", payload)
    }
  },
  mutations: {
    changeCurrency(state, payload) {
      state.currency = payload;
    },
    changeGeolocate(state, payload) {
      state.geolocation = payload;
    },
    setOrder(state, payload) {
      state.order = {...payload}
    }
  },
  getters: {
    getCurrency: (state) => state.currency,
    getGeo: (state) => state.geolocation,
    getOrder: (state) => state.order
  },
  plugins: [createPersistedState()],
});
