export default {
  getError(state) {
    return state.error
  },
  getUserData(state) {
    return state.user
  },
  getUserName(state) {
    return state.user.firstname;
  },
  getSession: (state) => state.AUTH_ID,
  isAuthenticated(state) {
    return !!state.user.customer_id
  }
}