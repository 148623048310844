<template>
  <div id="app">
    <the-header></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "./components/layouts/TheHeader.vue";
import TheFooter from "./components/layouts/TheFooter.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    TheHeader,
    TheFooter,
  },
  computed: {
    ...mapGetters("auth", ["getSession"]),
  },
  methods: {
    ...mapActions("auth", ["localAuth"]),

    async checkUserIsAuth() {
      const SESS_ID = window.$cookies.get("SESSID");
      if (!SESS_ID && !this.getSession) {
        // console.log("no sessid", SESS_ID);
        const responseData = await this.$axios(
          "https://back.rizaherb.kz/index.php?route=feed/rest_api/session"
        );

        const { data } = responseData.data;

        // console.log('window cookies ->>>', this.$cookies);
        // console.log("data sess ->", data.session);

        try {
          this.$cookies.set("SESSID", data.session);
          
        } catch (error) {
          console.error("cookie err", error);
        }
      }
    },
  },

  created() {
    this.checkUserIsAuth();
    this.$store.dispatch("cart/updateCart");
    this.$store.dispatch("wishlist/getWishList");
    this.localAuth();
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
  margin: 0 auto;
}

html,
body {
  font-size: 100%;
  line-height: 1;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
  color: #000;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
}

div {
  flex-wrap: wrap;
}
html,
body {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}
a,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none !important;
}

.old {
  text-decoration: line-through;
}
</style>
