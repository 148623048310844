import axios from "axios";
// import PublicAPI from "@/api/PublicAPI";

// Arrow function 'settings' using for avoiding dublicate code for axios settings;
const settings = ({ url, method = "GET" }, state) => {
  const SESS_ID = window.$cookies.get("SESSID");

  const setting = {
    url,
    method,
    headers: {
      "x-oc-merchant-id": process.env.VUE_APP_MERCHANT_ID,
      "x-oc-merchant-language": "ru-ru",
      "x-oc-session": SESS_ID,
      "x-oc-currency": state.currency
    },
  };

  return setting;
};

export default {
  async getProducts({ commit, dispatch, rootState}, payload) {
    const { id, limit, page, search } = payload;
    // console.log("id:", id, "limit:", limit, "page:", page, " search: ", search);

    try {
      //*ASK: If PublicAPI it`s already existed asynchronous methods, do we need again cover "getProducts" to async?
      // const products = await PublicAPI.getProducts(payload);

      if (search) {
        return dispatch("searchProduct", search);
      }

      const responseData = await axios(
        settings({
          url: `https://back.rizaherb.kz/index.php?route=feed/rest_api/products&category=${id ||
            ""}&limit=${limit || ""}&page=${page || ""}`,
        }, rootState)
      );
      const { data } = responseData.data;

      // Execute when user put on filter button

      if (id) {
        return commit("setProducts", data);
      }

      commit("setProducts", data);
    } catch (err) {
      console.log(err);
    }
  },

  async searchProduct({ commit, rootState }, search) {
    const searchResp = await axios(
      settings({
        url: `https://back.rizaherb.kz/index.php?route=feed/rest_api/products&search=${search}`,
      }, rootState)
    );

    const {
      data: { data },
    } = searchResp;

    if (data.length === 0) {
      return;
    }

    commit("setProducts", data);
  },

  async getRelatedProducts({ commit, rootState }, payload) {
    // const related = [];
    // ReadableStreamDefaultReader.push
    if (Array.isArray(payload)) {
      let allRelated = [];
      for(let item of payload) {
        const responseRelated = await axios(settings({url: `https://back.rizaherb.kz/index.php?route=feed/rest_api/related&id=${item}`}, rootState));
        const { data } = responseRelated.data;
        allRelated.push(...data);
        commit("setRelatedProducts", allRelated);
      }
    } else {
      const responseRelated = await axios(settings({url: `https://back.rizaherb.kz/index.php?route=feed/rest_api/related&id=${payload}`}, rootState));
      const { data } = responseRelated.data;

      commit("setRelatedProducts", data);
    }
    // const responseRelated = await this.$axios(`https://back.rizaherb.kz/index.php?route=feed/rest_api/related&id=${this.id}`);
    // const { data } = responseRelated.data;
    // this.relatedProducts = data;
  },
};
