import axios from "axios";

const settings = ({url, method}) => {
  const SESS_ID = window.$cookies.get('SESSID');

  const setting = {
    url,
    method,
    headers: {
      "x-oc-merchant-id": process.env.VUE_APP_MERCHANT_ID,
      "x-oc-merchant-language": "ru-ru",
      "x-oc-session": SESS_ID
    },
  };

  return setting
}

export default {
  
  async addToWishList({ dispatch }, id) {
    // PUT item to wishlist
    await axios(settings({url: `https://back.rizaherb.kz/index.php?route=rest/wishlist/wishlist&id=${id}`, method: "POST"}));

    // and after send request to wishlist to get list of all items contains in wishlist and than adding them to vuex
    dispatch('getWishList')
  },

  async getWishList({commit}) {
    const responseList = await axios(settings({url: 'https://back.rizaherb.kz/index.php?route=rest/wishlist/wishlist', method: "GET"}));
    const {data: {data}} = responseList;
    commit('setWishList', data)
  },

  async removeFromWishList({ commit }, id) {
    const resp = await axios(settings({url: `https://back.rizaherb.kz/index.php?route=rest/wishlist/wishlist&id=${id}`, method: "DELETE"}))
    console.log(resp)
    commit('removeFromWish', id)
  }
}