import axios from 'axios';

// Settings for axios request
const settings = ({ url, method, data, SESS }) => {
  const SESS_ID = SESS || window.$cookies.get("SESSID");

  const header = {
    url,
    method,
    headers: {
      "x-oc-merchant-id": process.env.VUE_APP_MERCHANT_ID,
      "x-oc-merchant-language": "ru-ru",
      "x-oc-session": SESS_ID,
    },
    data,
  };

  return header;
};

export default {

  // If user registered add mode "LOGIN" and apply to auth method
  
  async login(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'login'
    })
  },

  // If user set registration form add mode "SIGNUP" and apply to auth method

  async signup(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'signup'
    })
  },

  // The main form apply to api login or signup.

  async auth(context, payload) {

    // Check mode for api request, but this method not corresponding method of single responsibility!
    const mode = payload.mode;

    // Initialy it's "signup" url

    let url = "https://back.rizaherb.kz/index.php?route=rest/register/register";

    // If user already registered set url for "login"

    if (mode === 'login') {
      url = "https://back.rizaherb.kz/index.php?route=rest/login/login"
    }

    // Create new session id for the user, but first of all clean cookies from old session
    window.$cookies.remove('SESSID');

    const responseData = await axios(
      "https://back.rizaherb.kz/index.php?route=feed/rest_api/session"
    );

    const { data } = responseData.data;

    const SESS_ID = data.session;
    window.$cookies.set("SESSID", data.session);

    try {

      //  Waiting response from server
      const respData = await axios(settings({
        url,
        method: "POST",
        data: payload
      }));

      // console.log('sessid after login ->>>>>', window.$cookies.get('SESSID'));

      // After successed response, pull user and expirationData for lifetime session to LocalStorage
      // (but now isn`t actually)

      const { data: { data } } = respData;

      context.commit('setUser', {SESS_ID, data});
      context.dispatch('cart/updateCart', null, {root: true})
      context.dispatch('wishlist/getWishList', null, {root: true})
    } catch (err) {
      const {
        data: { error },
      } = err.response; // Error is in response property
      context.commit('saveErr', error)
    }
  },

  async logout(context) {

    await axios(settings({
      url: 'https://back.rizaherb.kz/index.php?route=rest/logout/logout',
      method: "POST",
      SESS: context.state.AUTH_ID
    }));

    window.$cookies.remove('SESSID');
    context.commit('setUser', {});
      context.dispatch('cart/updateCart', null, {root: true})
      context.dispatch('wishlist/getWishList', null, {root: true})  
    
    // Create session for unauthenticated user
    const responseData = await axios(
      "https://back.rizaherb.kz/index.php?route=feed/rest_api/session"
    );

    const { data } = responseData.data;

    try {
      window.$cookies.set("SESSID", data.session);
    } catch (error) {
      console.error('cookie err', error);
    }

  },

  // This method call when App.vue rendering and check if the user in localStorage and expiration date is ok.
  // Experation time is old variant for observing user in a system, new method after reload always synchronized it with the server
  async localAuth(context) {

    // const SESS_ID = context.state.AUTH_ID || window.$cookies.get('SESSID')

    const isLogIn = await axios(settings({
      url: 'https://back.rizaherb.kz/index.php?route=feed/rest_api/customerlogged',
      method: "GET",
      SESS: context.state.AUTH_ID
    }))

    const { data: { data } } = isLogIn;
    
    if(!data) {
      context.commit('setUser', {})
      return
    }
  },

  async changeUserData({commit}, payload) {
    const changed = await axios(settings({
      url: "https://back.rizaherb.kz/index.php?route=rest/account/account",
      method: "PUT",
      data: {
        ...payload
      }
    }));
    console.log(changed)
    commit("changeFieldsData", payload)
  },

  async changeUserAddress({commit}, {address_id, ...args}) {
    const address = await axios(settings({
      url: `https://back.rizaherb.kz/index.php?route=rest/account/address&id=${address_id}`,
      method: "PUT",
      data: {
        ...args
      }
    }));
    console.log(address)
    commit("changeFieldsAddress", args)
  },
  // This method call when user @input new data
  clearErrors({commit}) {
    commit('saveErr', [])
  }
}