export default {
  // Initialy execute when user open Category page or set "SHOW MORE" items
  setProducts(state, payload) {
    state.products = payload;
  },
  setRelatedProducts(state, payload) {
    // ASK: Can I clear the storage first and then add new items?
    state.relatedProducts = [];
    state.relatedProducts.push(...payload)
  }
}