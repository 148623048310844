import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: () => import('../views/Catalog.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products/index.vue')
  },
  {
    path: '/products/:id',
    props: true,
    name: 'ProductDetail',
    component: () => import('../views/Products/_id.vue'),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/Blog/Blog.vue")
  },
  {
    path: "/blog/:id",
    props: true,
    name: "ArticleDetail",
    component: () => import("../views/Blog/_id.vue")
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Authentication.vue'),
  },
  {
    path: '/personal',
    meta: { auth: true },
    name: 'User',
    component: () => import('../components/auth/UserPersonal.vue')
  },
  {
    path: '/paymentsdelivery',
    name: "PaymentsDelivery",
    component: () => import('../views/PaymentsDelivery.vue')
  },
  {
    path: '/fogotten',
    name: "Password",
    component: () => import('../components/auth/Password.vue')
  },
  {
    path: '/wishlist',
    meta: { auth: true },
    name: 'Wish',
    component: () => import('../views/WishList.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    meta: {isEmpty: true},
    component: () => import('../views/Cart.vue'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/payment-by-kaspi',
    name: 'Kaspi',
    component: () => import("../views/PaymentByKaspi.vue")
  },
  {
    name: "NotFound",
    path: '/:NotFound(.*)',
    component: () => import('../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to) {
    if (to.name === 'ProductDetail') {
      return {
        x: 0,
        y: 0
      }
    }
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    // return {
    //   x: 0,
    //   y: 0
    // }
  },
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _, next) => {
  if(to.meta.auth && !store.getters['auth/isAuthenticated']) {
    next('/auth')
  } 
  next()
})

export default router
