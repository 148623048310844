export default {
  // Change store cart when user press addToCart or when App is started
  pushToCart(state, { products, totals }) {
    state.cart = products && products.length > 0 ? products : [];
    state.totalsAmount = totals && totals.length > 0 ? totals : [];
    state.discount = totals && totals[1].discount;
  },

  // Adding and deduction quantity of the item in cart,
  //*ASK: why I can't use in decrement method, when quanity lower than 1 => this.removeFromCart(key)
  // Or I should apply mutatation as argument after use {name of method} ?

  increment(state, id) {
    const itemCart = state.cart.find(el => el.product_id === id)
    itemCart.quantity = +itemCart.quantity + 1; 
  },

  decrement(state, id) {
    const itemCart = state.cart.find(el => el.product_id === id)
    if(itemCart.quantity - 1 < 1) {
      state.cart = state.cart.filter(item => item.product_id !== id);
    }
    itemCart.quantity -= 1
  },
  setQtyToCart(state, { id, qty }) {
    const itemCart = state.cart.find(el => el.product_id === id);
    itemCart.quantity = qty;
  },

  // Remove from store
  removeFromCart(state, key) {
    state.cart = state.cart.filter(item => item.key !== key);
    if (!state.cart.length) {
      state.discount = 0;
    }
  },

  recountTotal(state, discount) {
    state.discount = parseInt(discount);
  },
 
  setPrice(state, payload) {
    state.price = payload;
  }
}